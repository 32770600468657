@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-wallet-light-base text-wallet-light-primary dark:bg-wallet-base dark:text-wallet-primary;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

div:focus {
  outline: none;
}

div[data-tippy-root] {
  max-width: 75%;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
